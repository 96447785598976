.page-heading {
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-header {
    margin-bottom: 0 !important;
    flex-shrink: 0;
  }

  .inner-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .page-heading {
    flex-direction: column;

    .inner-flex {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 450px) {
  .page-heading {
    flex-direction: column;

    .inner-flex {
      flex-direction: column;

      button {
        margin-top: 20px;
      }
    }
  }
}
