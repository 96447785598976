@import "~scss/variables";

.sider {
  min-height: 100vh;
  position: fixed;
  width: 250px;
  top: 0;
  left: 0;
}

.layout {
  margin-left: 250px;
  min-height: 100vh;

  // &.storekeeper {
  //   margin-left: 0;
  // }
}

.site-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: right;
  padding: 0 40px !important;
  background-color: #fff;
  white-space: pre-wrap;
}

.logout-button {
  max-width: 100%;
  margin-left: 20px;
}

.nav-text {
  margin-left: 5px;
}

.nav-icon {
  margin-right: 10px !important;
}

.logo {
  padding: 12px 24px;
}

.main {
  padding: 20px;
  min-height: calc(100vh - 64px);
  position: relative;
}

.background {
  position: relative;
  padding: 20px;
  background-color: #fff;
  height: 100%;
  min-height: calc(100vh - 104px);
}

.orange-title {
  color: $primary !important;
  margin: 0 auto 0 0 !important;
}
