@import "~scss/variables";

.auth-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 30px;
}

.auth-wrapper {
  position: relative;
  max-width: 500px;
  width: 100%;
  padding: 30px;
  border-radius: 3px;

  background-color: #fff;
  z-index: 10;

  border-bottom: 5px solid $primary;
  overflow: hidden;
}

.auth-title {
  color: $primary !important;
  text-align: center;
}

.auth-logo {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}
