@import "./variables";

.particles-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #001529;
  z-index: -1;
}

.primary-text {
  color: $primary;
}

.ant-menu-dark {
  .ant-menu-submenu-active {
    color: #fff;
  }

  .ant-menu-submenu-open {
    color: rgba($color: #fff, $alpha: 0.65) !important;
  }
}
